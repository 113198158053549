<!--
  PACKAGE_NAME : src\pages\report\wizard\detail.vue
  FILE_NAME : detail
  AUTHOR : kwmoon
  DATE : 2024-04-22
  DESCRIPTION : 보고서 위자드 상세 페이지 (추가, 수정 시)
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <!-- Buttons-->
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex justify-between">
            <div class="flex py-4 space-x-1">
              <DxButton text="목록 가기" class="btn_XS white light_filled" :height="30" @click="routeReportWizard" />
              <DxButton text="쿼리 불러오기" class="btn_XS default filled" :height="30" @click="onClickTogglePopup('template', true)" />
            </div>
            <div class="flex py-4 space-x-1">
              <DxButton text="초기화" class="btn_XS white light_filled" :height="30" />
              <DxButton
                text="보고서 저장"
                class="btn_XS default filled"
                id="Save"
                :height="30"
                @click="handleClickSaveReport"
                validation-group="validationGroupName"
              />
            </div>
          </div>
        </div>
        <!-- Buttons-->

        <!-- Form -->
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex justify-between">
            <div class="flex py-4 space-x-5">
              <div class="flex space-x-2">
                <div class="mt-1">보고서명*</div>
                <DxTextBox v-model="form.name" :width="200" :height="30" styling-mode="outlined">
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="보고서명은 필수 입니다." />
                  </DxValidator>
                </DxTextBox>
              </div>
              <div class="flex space-x-2">
                <div class="mt-1">파일명(영문)*</div>
                <DxTextBox v-model="form.fileNm" :width="200" :height="30" styling-mode="outlined">
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="파일명은 필수 입니다." />
                  </DxValidator>
                </DxTextBox>
              </div>
              <div class="flex space-x-2">
                <div class="mt-1">소메뉴*</div>
                <DxSelectBox
                  placeholder="선택"
                  :items="reportMenu.depth2"
                  display-expr="menuNm"
                  value-expr="id"
                  v-model="form.parentMenuId"
                  :width="150"
                  :height="30"
                  :readOnly="reportId !== null"
                  styling-mode="outlined"
                >
                  <DxValidator validation-group="validationGroupName">
                    <DxRequiredRule message="소메뉴 선택은 필수 입니다." />
                  </DxValidator>
                </DxSelectBox>
              </div>
              <div class="flex space-x-2">
                <div class="mt-1">솔루션*</div>
                <DxTextBox v-model="form.solution" styling-mode="outlined" :width="120" :height="30" :readOnly="true" />
              </div>
              <div class="flex space-x-2">
                <div class="mt-1">구분*</div>
                <DxTextBox v-model="form.subPath" styling-mode="outlined" :width="120" :height="30" :readOnly="true" />
              </div>
            </div>
            <div class="flex py-4">
              <DxButton v-if="hasReportId()" text="XML" class="btn_XS white light_filled" :height="30" @click="toggleXmlModal(true)" />
            </div>
          </div>
        </div>
        <!-- Form -->
      </div>
      <!-- Main  -->
      <div class="flex gap-x-4 divide-x items-stretch">
        <div class="w-3/12 py-4">
          <div>
            <h3 class="text-xl font-medium mb-2">항목</h3>
            <DxDataGrid
              class="grid-box"
              id="grid"
              :data-source="grid.falseColumnGrid"
              :show-borders="false"
              :show-column-headers="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="false"
              :allow-column-reordering="true"
              :no-data-text="noDataTextTarget(grid.falseColumnGrid.length)"
              :selected-row-keys="grid.selectedItemKeys"
              @selection-changed="grid.selectionChanged"
              width="100%"
              height="calc(100vh - 380px)"
            >
              <DxFilterRow :visible="true">
                <DxOperationDescriptions contains="포함" />
              </DxFilterRow>
              <DxPaging :page-size="2000" />
              <DxRowDragging :on-add="onDragTrueToFalseColumn" group="taskVisibleColumnGroup" />
              <DxColumn
                caption="항목명"
                data-field="caption"
                :fixed="false"
                alignment="left"
                :visible="true"
                cell-template="titleTemplate"
              />

              <template #titleTemplate="{ data }">
                <a class="font-bold" @click="toggleColumnPopup(true, data, 'falseColumnGrid')">
                  {{ data.value }}
                </a>
              </template>
              <DxColumn caption="컬럼명" 기 data-field="dataField" :fixed="false" alignment="left" />
              <DxColumn caption="적용" cell-template="moveTemplate" :fixed="false" alignment="center" width="80" />
              <template #moveTemplate="{ data }">
                <DxButton
                  text="선택"
                  type="button"
                  class="btn_XS white light_filled"
                  :width="50"
                  :height="30"
                  @click="onClickFalseToTrueColumn({ data })"
                />
              </template>
            </DxDataGrid>
          </div>
        </div>
        <div class="w-8/12 flex flex-col gap-y-6 divide-y">
          <div class="py-4 px-4">
            <h3 class="text-xl font-medium ml-10 mb-2">미리 보기</h3>
            <DxDataGrid
              :ref="grid.previewGrid.refName"
              :show-borders="false"
              :data-source="grid.previewGrid.datas"
              :show-column-headers="true"
              :show-column-lines="false"
              :show-row-lines="true"
              :height="120"
              :width="grid.previewGrid.width"
              :allow-column-reordering="false"
              :allow-column-resizing="false"
              column-resizing-mode="widget"
              :column-auto-width="false"
              no-data-text="미리 확인할 데이터가 존재하지 않습니다."
            >
              <template v-for="(item, i) in previewGridColumns">
                <template v-if="item.length > 0 && [null, ''].indexOf(item[0].multiHeaderNm) === -1">
                  <DxColumn :caption="item[0].multiHeaderNm" :key="i">
                    <DxColumn
                      v-for="(item2, i2) in item"
                      alignment="center"
                      :key="`${i}_${i2}`"
                      :allow-sorting="false"
                      :caption="item2.caption"
                      :dataField="item2.dataField"
                      css-class="grid-header-border"
                      :cellTemplate="cellTemplateByColumnFmt(item2.format)"
                    />
                  </DxColumn>
                </template>
                <template v-else>
                  <template v-if="item.grouping">
                    <DxColumn
                      :key="i"
                      :allow-sorting="false"
                      :caption="item.caption"
                      :dataField="item.dataField"
                      :alignment="item.align"
                      :width="item.width"
                      :visible="item.visible"
                      :fixed="item.fixed"
                      :group-index="i"
                      :cellTemplate="cellTemplateByColumnFmt(item.format)"
                    ></DxColumn>
                  </template>
                  <template v-else>
                    <DxColumn
                      :key="i"
                      :allow-sorting="false"
                      :caption="item.caption"
                      :dataField="item.dataField"
                      :alignment="item.align"
                      :width="item.width"
                      :visible="item.visible"
                      :fixed="item.fixed"
                      :cellTemplate="cellTemplateByColumnFmt(item.format)"
                    ></DxColumn>
                  </template>
                </template>
              </template>
              <template #dateTemplate="{ data }">
                {{ fmtDate(data.value) }}
              </template>

              <template #percentTemplate="{ data }">
                {{ data.value + '%' }}
              </template>

              <template #timeTemplate="{ data }">
                {{ fmtTime(data.value) }}
              </template>

              <template #numberTemplate="{ data }">
                {{ fmtNumber(data.value) }}
              </template>

              <template #defaultTemplate="{ data }">
                {{ data.value }}
              </template>

              <template #treeTemplate="{ data }">
                {{ data.value }}
              </template>

              <template #linkTemplate="{ data }">
                <a>{{ data.value }}</a>
              </template>
            </DxDataGrid>
          </div>
          <div class="py-4 px-4">
            <h3 class="text-xl font-medium ml-10 mb-2">보고서 항목</h3>
            <DxDataGrid
              class="grid-box"
              id="grid"
              ref="grid"
              :data-source="grid.trueColumnGrid"
              :show-borders="false"
              :show-column-headers="true"
              :show-column-lines="false"
              :show-row-lines="true"
              :row-alternation-enabled="false"
              :allow-column-resizing="true"
              height="calc(100vh - 580px)"
              :visible="true"
              :selected-row-keys="grid.selectedItemKeys"
              @selection-changed="grid.selectionChanged"
              :no-data-text="noDataTextList(grid.trueColumnGrid.length)"
            >
              <DxSorting mode="none" />
              <DxPaging :enabled="false" :page-size="1000" />
              <DxRowDragging
                :on-add="onDragFalseToTrueColumn"
                group="taskVisibleColumnGroup"
                :allow-reordering="true"
                :on-reorder="onDragChangeOrderInTrueColumns"
              />
              <!-- 수정모드 -->
              <DxColumn caption="ID" data-field="id" :fixed="false" alignment="center" :visible="false" :allow-editing="false" />
              <DxColumn caption="멀티헤더명" :fixed="false" data-field="multiHeaderNm" alignment="center" :visible="true" :width="150" />
              <DxColumn
                caption="항목명"
                data-field="caption"
                :fixed="false"
                alignment="left"
                :visible="true"
                :allow-editing="false"
                :width="160"
                cell-template="titleTemplate"
              />
              <DxColumn
                caption="컬럼명"
                data-field="dataField"
                :fixed="false"
                alignment="left"
                :visible="true"
                :allow-editing="false"
                :width="150"
              />
              <DxColumn caption="넓이" data-field="width" :fixed="false" alignment="center" :allow-editing="true" :width="60" />
              <DxColumn caption="출력형식" data-field="format" :fixed="false" :allow-editing="true" alignment="center" :width="80">
                <DxLookup :data-source="tools.format" value-expr="id" display-expr="text" />
              </DxColumn>
              <DxColumn caption="정렬" :fixed="false" data-field="align" alignment="center" :width="90">
                <DxLookup :data-source="tools.align" value-expr="id" display-expr="text" />
              </DxColumn>
              <DxColumn caption="항목설명" data-field="description" :fixed="false" :allow-editing="true" alignment="left" />
              <DxColumn caption="출력여부" :fixed="false" data-field="visible" alignment="center" :visible="false" />
              <DxColumn caption="삭제" cell-template="moveTemplate" :width="60" :fixed="false" alignment="center" />
              <template #moveTemplate="{ data }">
                <button class="btn-icon close" type="button" @click="onClickTrueToFalseColumn({ data })"></button>
              </template>
              <template #titleTemplate="{ data }">
                <a class="font-bold" @click="toggleColumnPopup(true, data, 'trueColumnGrid')">
                  {{ data.value }}
                </a>
              </template>
              <DxScrolling row-rendering-mode="virtual" />
            </DxDataGrid>
          </div>
        </div>
        <div class="w-1/12 py-4">
          <div class="px-4">
            <table class="line-bin">
              <tbody>
                <tr v-for="{ id, title, col, type } in checkListLayout" :key="id">
                  <template v-if="type === 'title'">
                    <td :colspan="col" class="pt-16">
                      <h2 class="layout-title">{{ title }}</h2>
                    </td>
                  </template>
                  <template v-if="type === 'checkbox'">
                    <td class="w32">
                      <DxCheckBox v-model="searchTag[id]" />
                    </td>
                    <td class="pointer indent-1 py-2" @click="onClickLabel(`searchTag.${id}`)">
                      {{ title }}
                    </td>
                  </template>
                </tr>
                <tr>
                  <td colspan="2" class="pt-16">
                    <h2 class="layout-title">조회 대상</h2>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="py-2">
                    <DxSelectBox
                      placeholder="선택"
                      :items="reportTarget.items"
                      display-expr="name"
                      styling-mode="outlined"
                      value-expr="id"
                      v-model="reportTarget.selectedId"
                      width="100%"
                      :height="30"
                    >
                      <DxValidator validation-group="validationGroupName">
                        <DxRequiredRule message="조회대상 선택은 필수 입니다." />
                      </DxValidator>
                    </DxSelectBox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- XML 팝업 -->
      <CustomDxPopup :isOpen="popup.xml.isOpen" :option="popup.xml.option" @saveModal="saveXmlModal" @closeModal="toggleXmlModal(false)">
        <template #content>
          <XmlEditor ref="xmlEditor" :options="popup.xml.editorOption" :history="popup.xml.xmlHistories" :reportId="reportId" />
        </template>
      </CustomDxPopup>

      <!-- 컬럼 설정 팝업 -->
      <CustomDxPopup
        :isOpen="popup.column.isOpen"
        :option="popup.column.option"
        @saveModal="updateColumnInfo"
        @closeModal="toggleColumnPopup(false)"
      >
        <template #content>
          <UpdateColumnPopup ref="updateColumnPopup" :tools="tools" :column="popup.column.data" />
        </template>
      </CustomDxPopup>

      <!-- 템플릿 -->
      <CustomDxPopup
        :isOpen="popup.template.isOpen"
        :option="popup.template.option"
        @saveModal="asyncApplyTemplatePopup"
        @closeModal="onClickTogglePopup('template', false)"
      >
        <template #content>
          <Tabs id="wizard-detail-page" ref="templateTabs" @selectedIndex="selectedTemplatePopupTab">
            <Tab :key="tab.id" :title="tab.name" v-for="tab in popup.template.tabs">
              <DxDataGrid
                width="100%"
                :ref="getTemplatePopupDataGridRef(tab.id)"
                :data-source="popup.template[`${tab.id}List`]"
                :show-borders="false"
                :show-column-headers="true"
                :show-column-lines="false"
                :show-row-lines="true"
                :row-alternation-enabled="false"
                :allow-column-resizing="true"
                :height="420"
                :visible="true"
                :no-data-text="noDataTextList(popup.template[`${tab.id}List`].length)"
              >
                <DxSorting mode="none" />
                <DxSelection mode="single" />
                <DxPaging :enabled="false" :page-size="1000" />
                <DxColumn
                  :key="dataField"
                  :caption="caption"
                  :data-field="dataField"
                  :width="width"
                  :alignment="align ? align : 'center'"
                  v-for="{ caption, dataField, align, width } in tab.columns"
                />
              </DxDataGrid>
            </Tab>
          </Tabs>
        </template>
      </CustomDxPopup>
    </div>
  </div>
</template>

<script>
  import {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxRowDragging,
    DxLookup,
    DxPaging,
    DxSelection,
    DxFilterRow,
    DxOperationDescriptions,
  } from 'devextreme-vue/data-grid';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import DxCheckBox from 'devextreme-vue/check-box';
  import CustomDxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  import { isSuccess, getResData, formatDate, isTrue, isEmpty } from '@/plugins/common-lib';
  import UpdateColumnPopup from '@/pages/report/wizard/popup/update-column-popup.vue';

  export default {
    components: {
      DxOperationDescriptions,
      Tab,
      Tabs,
      // 모달
      CustomDxPopup,
      XmlEditor,
      // Dev-Extreme
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxTextBox,
      DxButton,
      DxSelectBox,
      DxRowDragging,
      DxLookup,
      DxCheckBox,
      UpdateColumnPopup,
      DxPaging,
      DxSelection,
      DxValidator,
      DxRequiredRule,
      DxFilterRow,
      DxSorting,
    },
    props: {},
    watch: {},
    data() {
      return {
        // query string 으로 들어오는 rid 값
        // 새로 생성 시 "rid"는 존재하지 않으며, 수정시에는 존재
        reportId: null,
        // 2번째 라인 Input 박스
        form: {
          name: null, //보고서명
          fileNm: null,
          //소메뉴
          parentMenuList: this.getReportMenuList(2),
          parentMenuId: null,
          //솔루션 및 서브패스
          solution: null,
          subPath: null,
        },
        reportMenu: {
          depth2: this.getReportMenuList(2),
          depth3: this.getReportMenuList(3),
          selectedId: null,
        },
        grid: {
          falseColumnGrid: [], // 항목 (visible: false)
          trueColumnGrid: [], // 보고서항목 (visible: true)
          previewGrid: {
            refName: 'previewGrid',
            width: null,
            columns: [],
            datas: [],
          },
          selectedItemKeys: [],
          selectionChanged: data => {
            this.grid.selectedItemKeys = data.selectedRowKeys;
          },
        },
        // 체크박스
        searchTag: {
          //검색기간
          periodDay: true, //일
          periodMonth: true, //월
          periodYear: true, //년
          periodRange: true, //기간
          //보고서 양식
          typeDay: true, //일별
          typeMonth: true, //월별
          typeHour: true, //시간별
          typeI30: true, //30분별
          typeI15: true, //15분별
          typeDayHour: true, //일+시간별
          //요일, 시간
          week: true, //요일
          time: true, //시간
          //페이징 처리 여부
          paging: true,
        },
        checkListLayout: [
          //검색 기간
          { id: 'title-search-date', title: '기간', type: 'title', col: 2 },
          { id: 'periodDay', title: '일별', type: 'checkbox' },
          { id: 'periodMonth', title: '월별', type: 'checkbox' },
          { id: 'periodYear', title: '년도별', type: 'checkbox' },
          { id: 'periodRange', title: '특정기간', type: 'checkbox' },
          //보고서 양식
          { id: 'title-report-type', title: '보고서 양식', type: 'title', col: 2 },
          { id: 'typeDay', title: '일별', type: 'checkbox' },
          { id: 'typeMonth', title: '월별', type: 'checkbox' },
          { id: 'typeHour', title: '시간별', type: 'checkbox' },
          { id: 'typeI30', title: '30분별', type: 'checkbox' },
          { id: 'typeI15', title: '15분별', type: 'checkbox' },
          { id: 'typeDayHour', title: '일+시간별', type: 'checkbox' },
          //요일/시간
          { id: 'title-day-time', title: '요일/시간', type: 'title', col: 2 },
          { id: 'week', title: '요일', type: 'checkbox' },
          { id: 'time', title: '시간', type: 'checkbox' },
          //페이징 처리
          { id: 'title-paging', title: '페이징', type: 'title', col: 2 },
          { id: 'paging', title: '사용', type: 'checkbox' },
        ],
        reportTarget: {
          all: [],
          items: [],
          selectedId: null,
          getName: () => {
            return this.reportTarget.items.find(item => item.id === this.reportTarget.selectedId)?.name;
          },
        },
        popup: {
          template: {
            selectedInfo: { templateType: null, templateId: null },
            isOpen: false,
            option: {
              title: '쿼리 불리오기',
              width: '800px',
              height: '650px',
              minWidth: null,
              minHeight: null,
            },
            tabs: [
              {
                id: 'query',
                name: '템플릿 쿼리',
                columns: [
                  { caption: '솔루션', dataField: 'solution', width: 100 },
                  { caption: '구분', dataField: 'subPath', width: 100 },
                  { caption: '이름', dataField: 'name', width: 250 },
                  { caption: '설명', dataField: 'description', align: 'left' },
                ],
              },
              {
                id: 'menu',
                name: '보고서 메뉴',
                columns: [
                  { caption: '솔루션', dataField: 'solution', width: 100 },
                  { caption: '구분', dataField: 'subPath', width: 100 },
                  { caption: '소메뉴', dataField: 'parentMenu', width: 150 },
                  { caption: '메뉴명', dataField: 'menuNm' },
                ],
              },
            ],
            selectedTabId: null, // 선택한 탭 인덱스
            // 탭 별 그리드 내 데이터
            queryList: [],
            menuList: [],
          },
          column: {
            isOpen: false,
            option: {
              title: '항목 정보',
              width: '520',
              height: '550',
              minWidth: null,
              minHeight: null,
            },
            current: {
              grid: null,
              dataField: null,
            },
          },
          xml: {
            isOpen: false,
            option: {
              title: 'XML 관리',
              width: '80%',
              height: '85%',
              minWidth: null,
              minHeight: null,
            },
            editorOption: {
              type: 'REPORT',
              reportNm: '',
              reportId: this.reportId,
              useRight: true,
              rsWidth: '30%', // 우측 섹션 넓이
            },
            xmlHistories: [],
          },
        },
        tools: {
          align: [
            { id: 'center', text: '중앙' },
            { id: 'right', text: '우측' },
            { id: 'left', text: '좌측' },
          ],
          format: [
            { id: 'fmText', text: '텍스트' },
            { id: 'fmDate', text: '날짜' },
            { id: 'fmNumber', text: '숫자' },
            { id: 'fmPercent', text: '퍼센트(%)' },
            { id: 'fmtime', text: '시간' },
          ],
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          sendData: null,
          contentData: null,
          addOpt: {
            title: '항목 추가',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
            width: '600',
            height: '620',
          },
          uptOpt: {
            title: '항목 변경',
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
            width: '600',
            height: '620',
          },
          isOpenXmlModal: false,
          xmlModalOption: {
            title: 'XML 관리',
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'REPORT',
            reportNm: '',
            reportId: this.reportId,
            useRight: true,
            rsWidth: '30%', // 우측 섹션 넓이
          },
          xmlHistories: [],
        },
      };
    },
    computed: {
      previewGridColumns() {
        return this.getReplaceMultiHeaderColumn(this.grid.trueColumnGrid);
      },
    },
    methods: {
      getReportMenuList(menuDepth) {
        return this.$store.getters.getMenuList.filter(v => v.menuDepth === menuDepth && v.menuTypeCd === 'REPORT');
      },
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },
      fmtNumber(value) {
        let rtn = parseInt(value);
        return rtn.toLocaleString('ko-KR');
      },
      lPadZero(num) {
        if (num < 10) {
          return `0${num}`;
        }
        return num;
      },
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
      cellTemplateByColumnFmt(fmt) {
        let cellTemplate = null;
        if (['date', 'fmDate'].includes(fmt)) cellTemplate = 'dateTemplate';
        else if (['number', 'fmNumber'].includes(fmt)) cellTemplate = 'numberTemplate';
        else if (['percent', 'fmPercent'].includes(fmt)) cellTemplate = 'percentTemplate';
        else if (['time', 'fmtime'].includes(fmt)) cellTemplate = 'timeTemplate';
        else if (['tree', 'fmTree'].includes(fmt)) cellTemplate = 'treeTemplate';
        else if (['link', 'fmLink'].includes(fmt)) cellTemplate = 'linkTemplate';
        return cellTemplate;
      },
      onConfirmModal(e) {
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit('ModalAddColumn:onSaveData', e, resolve, reject);
        });

        promise
          .then(res => {
            if (res.type === 'ColumnAdd') {
              this.isOpenModal(false);
              this.onChangeSearchTypeRefresh();
            } else if (res.type === 'ColumnUpdate') {
              this.isOpenModal(false);
              this.tab1.grid.ReportList.forEach(data => {
                if (data.dataField == res.dataField) {
                  data.dataField = res.dataField;
                  data.caption = res.caption;
                  data.width = res.width;
                  data.format = res.format;
                  data.description = res.description;
                  data.align = res.align;
                  data.target = res.target;
                  data.visible = res.visible;
                  data.multiHeaderNm = res.multiHeaderNm;
                }
              });
              this.tab1.grid.ReportList = [...this.tab1.grid.ReportList];
            }
          })
          .catch(e => {
            console.log('컬럼 변경 오류: ', e);
            this.$_Toast('저장 작업 중 오류가 발생했습니다.');
          });
      },
      //XML 모달
      async asyncSetXmlHistoryByReportId(reportId) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_HISTORY_LIST',
          path: `/${reportId}`,
          loading: true,
        });

        if (isSuccess(res)) {
          this.popup.xml.xmlHistories = getResData(res);
        }
      },
      async toggleXmlModal(isTrue) {
        this.$refs.xmlEditor.clearVueData();
        if (isTrue) {
          this.popup.xml.editorOption.reportId = this.reportId;
          this.popup.xml.editorOption.reportNm = this.form.name;
          await this.asyncSetXmlHistoryByReportId(this.reportId);
        }
        this.popup.xml.isOpen = isTrue;
      },
      async asyncUpdateReportXmlData(useSqlPass, useErrorPopup) {
        const { cmEditor: reportXml, description } = this.$refs.xmlEditor;
        const params = { xmlData: reportXml, reportId: this.reportId, description: description };
        //XML, 설명, 레포트 ID
        return await this.CALL_REPORT_API({
          actionname: 'REPORT_XML_UPDATE',
          data: { ...params, isEnableQueryValidation: useSqlPass },
          loading: true,
          useErrorPopup: useErrorPopup,
        });
      },
      async saveXmlModal() {
        //XML, 설명, 레포트 ID
        const res = await this.asyncUpdateReportXmlData(false, false);
        const { resCode, resMsg } = res.data.header;
        if (isSuccess(res) && (await this.$_Msg('XML 파일이 편집되었습니다.'))) {
          location.reload();
        } else if (resCode !== 'XML012') {
          this.$_Msg(resMsg);
        } else if (resCode === 'XML012' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.asyncUpdateReportXmlData(true, true);
          if (isSuccess(forceRes) && (await this.$_Msg('XML 파일이 편집되었습니다.'))) {
            location.reload();
          }
        }
      },
      toggleColumnPopup(bool, data = null, gridName = null) {
        this.popup.column.isOpen = bool;
        if (bool) {
          const column = data.data;
          this.popup.column.current.grid = gridName;
          this.popup.column.current.dataField = column.dataField;
          this.$refs.updateColumnPopup.initColumnForm(column);
        }
      },
      updateColumnInfo() {
        const formData = this.$refs.updateColumnPopup.getForm();
        const gridName = this.popup.column.current.grid;
        this.grid[gridName].forEach(v => {
          if (v.dataField === formData.dataField) {
            Object.keys(formData).forEach(key => {
              v[key] = formData[key];
            });
          }
        });

        this.toggleColumnPopup(false);
      },
      noDataTextList(length) {
        if (length === 0) {
          return '선택된 항목이 없습니다. 좌측에서 드래그로 설정 할 수 있습니다.';
        }
      },
      noDataTextTarget(length) {
        if (length === 0) {
          return '상단 템플릿을 선택하여 주시기 바랍니다.';
        }
      },

      /**
       * @description
       * 보고서 항목(trueColumn) > 항목(falseColumn) <br>
       * 해당 메서드는 받는 쪽(항목)에 선언
       * */
      onDragTrueToFalseColumn(e) {
        this.grid.falseColumnGrid.push(e.itemData);
        this.grid.trueColumnGrid = this.grid.trueColumnGrid.filter(d => d.dataField != e.itemData.dataField);
      },
      /** @description [보고서 항목] 삭제(X) 버튼 클릭하여 [항목]으로 이동 */
      onClickTrueToFalseColumn(data) {
        let moveData = data.data.data;
        this.grid.falseColumnGrid.push(moveData);
        this.grid.trueColumnGrid = this.grid.trueColumnGrid.filter(d => d.dataField !== moveData.dataField);
      },
      /**
       * @description
       * 항목(falseColumn) -> 보고서 항목(trueColumn) <br>
       * 해당 메서드는 받는 쪽(보고서 항목)에 선언
       * */
      onDragFalseToTrueColumn(e) {
        this.grid.trueColumnGrid.splice(e.toIndex, 0, e.itemData);
        this.grid.falseColumnGrid = this.grid.falseColumnGrid.filter(d => d.dataField !== e.itemData.dataField);
      },
      /** @description [항목] 선택 버튼 클릭하여 [보고서 항목]으로 이동 */
      onClickFalseToTrueColumn(data) {
        let moveData = data.data.data;
        this.grid.trueColumnGrid.push(moveData);
        this.grid.falseColumnGrid = this.grid.falseColumnGrid.filter(d => d.dataField !== moveData.dataField);
      },
      /** @description [보고서 항목] 순서 변경 로직 */
      onDragChangeOrderInTrueColumns(e) {
        const toIndex = e.toIndex; // 변경할 자리
        const columns = this.grid.trueColumnGrid.filter(v => v.dataField !== e.itemData.dataField);
        columns.splice(toIndex, 0, e.itemData);
        this.grid.trueColumnGrid = columns;
      },
      /** @description: 목록가기 */
      routeReportWizard() {
        this.$router.push({ path: '/report/wizard/list' });
      },
      onClickLabel(id) {
        const ids = id.split('.');
        let target = this;
        for (let i = 0; i < ids.length - 1; i++) {
          const key = ids[i];
          target = target[key];
          if (target === undefined) return;
        }

        if (target) {
          const lastKey = ids[ids.length - 1];
          target[lastKey] = !target[lastKey];
        }
      },
      /** @description 화면 대비 그리드 사이즈(width) 조절 */
      setPreviewGridWidth() {
        this.grid.previewGrid.width = (window.innerWidth * 55) / 100;
      },
      /** @description: this.reportId 가 셋팅되어있는지 체크하는 메서드 */
      hasReportId() {
        return this.reportId !== null;
      },
      /**
       * @description: 라우트 query 에서 보고서 ID 조회
       * @return reportId || null(존재하지 않을 시 > 생성 시)
       * */
      setReportIdAtQueryString() {
        let reportId = null;
        try {
          reportId = this.$router.currentRoute.query.rid;
        } catch {
          reportId = null;
        }
        this.reportId = reportId === undefined ? null : reportId;
      },
      /** @description: 보고서 XML 정보 조회하는 API를 통해 데이터 셋팅 */
      async getReportXMLInfo(rpeortId) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_INFO_BY_ID',
          data: { reportId: rpeortId },
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res)[0];
        }
      },
      /** @description: 보고서 XML 정보 조회하는 API를 통해 데이터 셋팅 */
      async getWizardTemplateXMLInfo(id) {
        const res = await this.CALL_REPORT_API({
          actionname: 'WIZARD_TEMPLATE_INFO',
          data: { id: id },
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res)[0];
        }
      },
      /** @description: Mounted 시 실행 함수 */
      async initializeMountedData() {
        const targetList = await this.asyncGetXmlFileListFromType('RT');
        this.setAllReportTarget(targetList);
        // 메뉴 정보 가지고 오기 (보고서 수정 시)
        if (this.hasReportId()) {
          const xmlData = await this.getReportXMLInfo(this.reportId);
          // 보고서명&소메뉴
          this.setReportNm(xmlData.menuNm);
          this.setParentMenu(this.getMenuInfo(xmlData.menuId)?.parentId ?? null);
          await this.initializeUI(xmlData);
        }
        // [팝업] 쿼리 불러오기 - 데이터 셋팅
        this.setTemplateMenuList(await this.asyncGetReportMenu());
        this.setTemplateQueryList(await this.asyncGetXmlFileListFromType('WT'));
      },
      /** @description: "최초 접근(Mounted)" or "쿼리 불러오기 적용" 시 UI 및 Data 초기화 */
      async initializeUI(xmlData) {
        // 솔루션&구분 (<sql> 속성에 정의)
        this.setFileNm(xmlData.fileNm);
        this.setSolution(xmlData.solution);
        this.setSubPath(xmlData.subPath);

        // <search> 태그 데이터 셋팅
        this.setSearchTagChilds(xmlData);
        const { trueColumns, falseColumns } = this.segregateByVisibility(xmlData.columns);
        this.setNotUsedColumns(falseColumns); // 항목 (사용하지 않은 항목)
        this.setUsingColumns(trueColumns); // 보고서 항목

        // 조회 대상
        await this.$nextTick();
        this.setReportTargetItems();
        this.setReportTarget(xmlData.objType);
      },
      /** @description <search> 태그 내 자식 태그 데이터 셋팅 */
      setSearchTagChilds(xmlData) {
        //TODO: XML 태그명 변경 이후 (전) || (후) > (전) isTrue 부분 삭제 필요
        this.setSearchTag('periodDay', isTrue(xmlData.searchTypeDay) || isTrue(xmlData.periodDay));
        this.setSearchTag('periodMonth', isTrue(xmlData.searchTypeMonth) || isTrue(xmlData.periodMonth));
        this.setSearchTag('periodYear', isTrue(xmlData.searchTypeYear) || isTrue(xmlData.periodYear));
        this.setSearchTag('periodRange', isTrue(xmlData.searchTypeFromto) || isTrue(xmlData.periodRange));
        this.setSearchTag('typeDay', isTrue(xmlData.reportTypeDay) || isTrue(xmlData.typeDay));
        this.setSearchTag('typeMonth', isTrue(xmlData.reportTypeMonth) || isTrue(xmlData.typeMonth));
        this.setSearchTag('typeHour', isTrue(xmlData.reportTypeHour) || isTrue(xmlData.typeHour));
        this.setSearchTag('typeI30', isTrue(xmlData.reportTypeMin30) || isTrue(xmlData.typeI30));
        this.setSearchTag('typeI15', isTrue(xmlData.reportTypeMin15) || isTrue(xmlData.typeI15));
        this.setSearchTag('typeDayHour', isTrue(xmlData.reportTypeDayhour) || isTrue(xmlData.typeDayHour));
        this.setSearchTag('week', isTrue(xmlData.conditionWeeks) || isTrue(xmlData.week));
        this.setSearchTag('time', isTrue(xmlData.conditionTimes) || isTrue(xmlData.time));
        this.setSearchTag('paging', isTrue(xmlData.paging));
      },
      setSearchTag(key, value) {
        this.searchTag[key] = value;
      },
      setFileNm(fileNm) {
        if (fileNm !== null) this.form.fileNm = fileNm;
      },
      setSolution(solution) {
        if (solution !== null) this.form.solution = solution;
      },
      setSubPath(subPath) {
        if (subPath !== null) this.form.subPath = subPath;
      },
      setParentMenu(parentMenuId) {
        this.form.parentMenuId = parentMenuId;
      },
      setReportNm(name) {
        this.form.name = name;
      },
      setAllReportTarget(items) {
        this.reportTarget.all = items;
      },
      setReportTargetItems() {
        const solution = this.form.solution;
        const subPath = this.form.subPath;
        let items = this.reportTarget.all;
        if (solution !== null && subPath !== null) {
          items = this.reportTarget.all.filter(v => v.solution === solution.toLowerCase() && v.subPath === subPath.toLowerCase());
        } else if (solution !== null && subPath === null) {
          items = this.reportTarget.all.filter(v => v.solution === solution.toLowerCase());
        }
        this.reportTarget.items = items;
      },
      setReportTarget(target) {
        const data = this.reportTarget.items.find(item => item.name === target);
        this.reportTarget.selectedId = data ? data.id : null;
      },
      /**
       * U_XML_FILE 타입 별 리스트 반환 API 호출 메서드
       * @param type ['WT', 'BI', 'RT', 'DS']
       * @returns U_XML_FILE 데이터 리스트
       */
      async asyncGetXmlFileListFromType(type) {
        const res = await this.CALL_REPORT_API({
          actionname: 'MASTER_QUERY_LIST',
          data: { type: type },
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
      },
      /** @description menuId 로 메뉴 정보 얻는 메서드  */
      getMenuInfo(menuId) {
        return this.reportMenu.depth3.find(menu => menu.id === menuId);
      },
      /** @description 보고서 메뉴(U_REPORT_MENU) 가지고 오는 API */
      async asyncGetReportMenu() {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_WIZARD_MENU_LIST',
          data: { sort: '-regDt', pagesize: 1000 },
          loading: false,
        });

        if (isSuccess(res)) {
          const result = getResData(res).map(item => {
            const targetMenu = this.getMenuInfo(item.menuId);
            const parentId = targetMenu?.parentId ?? null;
            item.parentMenu = null;
            if (parentId !== null) {
              const parentMenu = this.reportMenu.depth2.find(menu => menu.id === parentId);
              item.parentMenu = parentMenu?.menuNm ?? null;
            }
            return { ...item };
          });
          return result;
        }
        return [];
      },
      /**
       * <col> 태그 내 visible 속성에 따라 컬럼을 분리하며, visible 속성이 없는 컬럼은 true로 분리
       * @param columns
       * @returns {{trueColumns: *, falseColumns: *}}
       */
      segregateByVisibility(columns) {
        const trueColumns = columns.filter(col => !Object.hasOwn(col, 'visible') || isTrue(col.visible));
        const falseColumns = columns.filter(col => Object.hasOwn(col, 'visible') && !isTrue(col.visible));
        return { trueColumns, falseColumns };
      },
      setNotUsedColumns(columns) {
        this.grid.falseColumnGrid = columns;
      },
      setUsingColumns(columns) {
        this.grid.trueColumnGrid = columns;
      },
      /**
       * XML에서 정의된 컬럼을 multiHeaderNm 기준으로
       * 병합하여 반환하는 함수
       * @param columns
       * @returns {*[]}
       */
      getReplaceMultiHeaderColumn(columns) {
        let result = [];
        columns.forEach(col => {
          if (isEmpty(col.multiHeaderNm)) {
            result.push(col);
          } else {
            const preData = result.at(-1);
            if (Array.isArray(preData) && preData.at(-1).multiHeaderNm === col.multiHeaderNm) {
              result.at(-1).push(col);
            } else {
              result.push([col]);
            }
          }
        });
        return result;
      },
      /**
       * 팝업 리스트
       * 1. 템플릿 (추가, 수정)
       * 2. 항목 설정 (추가, 수정)
       * 3. XML 편집창 (수정)
       */
      onClickTogglePopup(type, bool) {
        this.popup[type].isOpen = bool;
      },
      /** @description [팝업] 템플릿 - 그리드 ref 값 조회 */
      getTemplatePopupDataGridRef(id) {
        return `template-grid-${id}`;
      },
      /** @description [팝업] 템플릿 - 탭 변경(최초 or 클릭) 시 실행 */
      selectedTemplatePopupTab(index) {
        this.popup.template.selectedTabId = this.popup.template.tabs[index].id;
      },
      /** @description [팝업] 템플릿 - 탭('menu') 그리드 내 보고서 메뉴 셋팅 */
      setTemplateMenuList(items) {
        this.popup.template.menuList = items;
      },
      /** @description [팝업] 템플릿 - 탭 그리드('query') 내 보고서 템플릿 셋팅 */
      setTemplateQueryList(items) {
        this.popup.template.queryList = items;
      },
      /** @description [팝업] 템플릿 - 선택 후 저장 로직 */
      async asyncApplyTemplatePopup() {
        const selectedTabId = this.popup.template.selectedTabId;
        //Grid Instance 에서 선택된 Row Data 가져오기
        const selectedItems = this.$refs[this.getTemplatePopupDataGridRef(selectedTabId)].at(0).instance.getSelectedRowsData();
        if (!selectedItems || selectedItems.length === 0) return this.$_Msg('쿼리로 적용할 대상을 선택해주시기 바랍니다.');

        try {
          const id = selectedItems[0].id;
          const functionName = 'query' === selectedTabId ? 'getWizardTemplateXMLInfo' : 'getReportXMLInfo';
          this.popup.template.selectedInfo.templateId = id;
          this.popup.template.selectedInfo.templateType = selectedTabId;
          const xmlData = await this[functionName](id);
          await this.initializeUI(xmlData);
          this.popup.template.isOpen = false;
        } catch (err) {
          console.error(err);
          return this.$_Msg('쿼리 적용 중 문제가 발생하였습니다.');
        }
      },
      /** @description 선택한 조회 대상 id, name 조회 */
      getSelectedTarget() {
        const selectedId = this.reportTarget.selectedId;
        return this.reportTarget.items.find(v => v.id === selectedId);
      },
      /** @description 저장 버튼 클릭 시 발생 이벤트 (위자드를 활용한 보고서 추가 및 수정) */
      async handleClickSaveReport() {
        if ((await this.$_Confirm('보고서를 저장하시겠습니까?')) === false) {
          return;
        }

        // 보고서명, 소메뉴, 솔루션, 구분
        const menuNm = this.form.name;
        const fileNm = this.form.fileNm;
        const parentMenuId = this.form.parentMenuId;
        const solution = this.form.solution;
        const subPath = this.form.subPath;

        // 보고서 항목
        const visibleColumns = this.grid.trueColumnGrid.map(col => ({ ...col, visible: true }));
        const unVisibleColumns = this.grid.falseColumnGrid.map(col => ({ ...col, visible: false }));
        const columns = [...visibleColumns, ...unVisibleColumns];

        // 기간, 보고서 양식, 요일/시간, 페이징, 조회대상
        const reportTarget = this.reportTarget.getName();
        const searchTags = { reportTarget, ...this.searchTag };
        const params = { menuNm, fileNm, parentMenuId, solution, subPath, columns, searchTags };

        let res = null;
        if (this.hasReportId()) {
          res = await this.CALL_REPORT_API({
            actionname: 'REPORT_WIZARD_MENU_UPDATE',
            data: { reportId: this.reportId, ...params },
            loading: true,
          });

          if (isSuccess(res)) {
            this.$_Msg('보고서가 수정되었습니다.');
          }
        } else {
          const { templateId, templateType } = this.popup.template.selectedInfo;
          res = await this.CALL_REPORT_API({
            actionname: 'REPORT_WIZARD_MENU_ADD',
            data: { templateId, templateType, ...params },
            loading: true,
          });

          if (isSuccess(res)) {
            this.$_Msg('보고서가 생성되었습니다.');
            const rid = getResData(res)[0];
            const route = { path: '/report/wizard/detail' };
            route['query'] = { rid: rid };
            this.$router.push(route);
          }
        }
      },
    },
    updated() {},
    created() {
      this.setReportIdAtQueryString();
    },
    mounted() {
      // 미리보기 그리드 사이즈 조절
      this.setPreviewGridWidth();
      window.addEventListener('resize', this.setPreviewGridWidth);

      // 최초 접근 시 UI 및 Data 초기화
      this.initializeMountedData();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setPreviewGridWidth);
    },
    destroyed() {},
  };
</script>
<style scoped>
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .ml-10 {
    margin-left: 10px;
  }
  .pt-16 {
    padding-top: 16px;
  }

  .py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .layout-title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 6px;
  }

  .pointer {
    cursor: pointer;
  }
  .w32 {
    width: 32px;
  }
</style>
<style>
  #wizard-detail-page > .page-sub-box {
    padding: 0 20px;
  }
  #wizard-detail-page > .ecs-tab-box {
    padding: 0;
  }
</style>
